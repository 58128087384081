import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>ページが見つかりません。</h1>
    <p>
	メニューから再度アクセスをお願いします。
	</p>
  </Layout>
)

export default NotFoundPage
